

.h-fit {
    height: fit-content;
}

.w-fit {
    width: fit-content;
}

.disable-text-selection {
    -moz-user-select:none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
}